import { klass, scrollSmooth, forMobile } from './common'

const MINIMUM_DISTANCE = 15
const touchMoveData = {
  startX: 0,
  startY: 0,
  endX: 0,
  endY: 0,
  distanceX: 0,
  distanceY: 0,
  toTop: false,
  toRight: false,
  toBottom: false,
  toLeft: false,
}
const getTouch = {
  start: (e) => {
    e.preventDefault()
    touchMoveData.startX = e.touches[0].pageX
    touchMoveData.startY = e.touches[0].pageY
  },
  move: (e) => {
    e.preventDefault()
    touchMoveData.endX = e.changedTouches[0].pageX
    touchMoveData.endY = e.changedTouches[0].pageY
  },
  result: (e) => {
    e.preventDefault()
    touchMoveData.distanceX = Math.abs(touchMoveData.endX - touchMoveData.startX)
    touchMoveData.distanceY = Math.abs(touchMoveData.endY - touchMoveData.startY)

    // 右向きスワイプ 左へ
    touchMoveData.toRight =
      touchMoveData.endX < touchMoveData.startX &&
      touchMoveData.distanceX > MINIMUM_DISTANCE &&
      touchMoveData.distanceX > touchMoveData.distanceY
    // 左向きスワイプ 右へ
    touchMoveData.toLeft =
      touchMoveData.endX > touchMoveData.startX &&
      touchMoveData.distanceX > MINIMUM_DISTANCE &&
      touchMoveData.distanceX > touchMoveData.distanceY
    // 下向きスワイプ 上へ
    touchMoveData.toTop =
      touchMoveData.endY > touchMoveData.startY &&
      touchMoveData.distanceY > MINIMUM_DISTANCE &&
      touchMoveData.distanceX < touchMoveData.distanceY
    // 上向きスワイプ 下へ
    touchMoveData.toBottom =
      touchMoveData.endY < touchMoveData.startY &&
      touchMoveData.distanceY > MINIMUM_DISTANCE &&
      touchMoveData.distanceX < touchMoveData.distanceY
  },
}

let hashLink = false

const serviceFunc = () => {
  if (document.querySelector('.service-outline')) {
    const body = document.querySelector('body')

    const i = {
      current: 0,
      WHEEL_INTERVAL: 1500,
      lastTime: 0,
      header: document.querySelector('.header'),
      pageTitle: document.querySelector('.page-visual'),
      breadCrumbs: document.querySelector('.breadcrumbs'),
      nav: document.querySelector('.service-nav'),
      wrapper: document.querySelector('#outline'),
      content: document.querySelector('.service-outline__content'),
      after: document.querySelector('.page-service__after-content'),
      footer: document.querySelector('.footer'),
      flag: {
        view: false,
        throw: false,
        throw2: false,
      },
      item: [
        document.querySelector('.service-outline__item00').classList,
        document.querySelector('.service-outline__item01').classList,
        document.querySelector('.service-outline__item02').classList,
        forMobile()
          ? document.querySelector('.service-outline__item03sp').classList
          : document.querySelector('.service-outline__item03').classList,
        // document.querySelector('.service-outline__item04').classList,
      ],
      paginationList: document.querySelector('.service-outline__pagination'),
      paginationPrev: document.querySelector('.slide-pagination__prev'),
      paginationNext: document.querySelector('.slide-pagination__next'),
      pagination: [
        document.querySelector('.service-outline__pagination-item00'),
        document.querySelector('.service-outline__pagination-item01'),
        document.querySelector('.service-outline__pagination-item02'),
        document.querySelector('.service-outline__pagination-item03'),
      ],
      bg: [
        document.querySelector('.service-outline__bg01').classList,
        document.querySelector('.service-outline__bg02').classList,
        document.querySelector('.service-outline__bg03').classList,
      ],
    }

    /**
     * 非表示コンテンツ調整
     * @param hidden {boolean}
     */
    const setHiddenContent = (hidden) => {
      if (hidden) {
        i.after.style.height = '0px'
        i.footer.style.height = '0px'
        i.footer.style.margin = '0'
      } else {
        i.after.style.height = ''
        i.footer.style.height = ''
        i.footer.style.margin = ''
      }
    }

    if (location.hash) {
      scrollSmooth(location.hash)
      hashLink = true
    } else {
      setHiddenContent(true)
    }

    /**
     * コンテンツポジションフラグ
     * @type {string} 'top' | 'center' | 'bottom'
     */
    let touchEvent = window.ontouchstart
    let touchPoints = navigator.maxTouchPoints

    let outline03List = forMobile()
      ? Array.from(document.querySelectorAll('.service-outline__item03sp-list li'))
      : Array.from(document.querySelectorAll('.service-outline__item03-list li'))

    // let outline03List = Array.from(document.querySelectorAll('.service-outline__item03-list li'))

    const outline03ListToggle = (show) => {
      let diff = 0
      outline03List.forEach((list) => {
        if (show) {
          setTimeout(() => {
            list.classList.add(klass.view)
          }, (diff += 200))
        } else {
          list.classList.remove(klass.view)
        }
      })
    }
    const pageAnimation = {
      to0: () => {
        i.current = 0
        i.item[0].add(klass.current)
        i.item[1].remove(klass.hidden)
        i.item[1].remove(klass.current)
        i.item[2].remove(klass.current)
        i.item[3].remove(klass.current)
        // i.item[4].remove(klass.current)
        i.bg[0].add(klass.current)
        i.bg[1].remove(klass.current)
        i.bg[2].remove(klass.current)
        i.pagination[0].classList.add(klass.current)
        i.pagination[1].classList.remove(klass.current)
        i.pagination[2].classList.remove(klass.current)
        i.pagination[3].classList.remove(klass.current)
        // i.pagination[4].classList.remove(klass.current)
        outline03ListToggle(false)
        i.paginationList.classList.add('is-first')
        i.paginationList.classList.remove('is-last')
      },
      to1: () => {
        i.current = 1
        i.item[0].remove(klass.current)
        i.item[1].remove(klass.hidden)
        i.item[1].add(klass.current)
        i.item[2].remove(klass.current)
        i.item[3].remove(klass.current)
        // i.item[4].remove(klass.current)
        i.bg[0].add(klass.current)
        i.bg[1].remove(klass.current)
        i.bg[2].remove(klass.current)
        i.pagination[0].classList.remove(klass.current)
        i.pagination[1].classList.add(klass.current)
        i.pagination[2].classList.remove(klass.current)
        i.pagination[3].classList.remove(klass.current)
        // i.pagination[4].classList.remove(klass.current)
        outline03ListToggle(false)
        i.paginationList.classList.remove('is-first')
        i.paginationList.classList.remove('is-last')
      },
      to2: () => {
        i.current = 2
        i.item[0].remove(klass.current)
        i.item[1].add(klass.hidden)
        i.item[2].add(klass.current)
        i.item[3].remove(klass.current)
        // i.item[4].remove(klass.current)
        i.bg[0].remove(klass.current)
        i.bg[1].add(klass.current)
        i.bg[2].remove(klass.current)
        i.pagination[0].classList.remove(klass.current)
        i.pagination[1].classList.remove(klass.current)
        i.pagination[2].classList.add(klass.current)
        i.pagination[3].classList.remove(klass.current)
        // i.pagination[4].classList.remove(klass.current)
        outline03ListToggle(false)
        i.paginationList.classList.remove('is-first')
        i.paginationList.classList.remove('is-last')
      },
      to3: () => {
        i.current = 3
        i.item[0].remove(klass.current)
        i.item[1].add(klass.hidden)
        i.item[1].remove(klass.current)
        i.item[2].remove(klass.current)
        i.item[3].add(klass.current)
        // i.item[4].remove(klass.current)
        i.bg[0].remove(klass.current)
        i.bg[1].remove(klass.current)
        i.bg[2].add(klass.current)
        i.pagination[0].classList.remove(klass.current)
        i.pagination[1].classList.remove(klass.current)
        i.pagination[2].classList.remove(klass.current)
        i.pagination[3].classList.add(klass.current)
        // i.pagination[4].classList.remove(klass.current)
        setTimeout(() => {
          outline03ListToggle(false)
          outline03ListToggle(true)
        }, 600)

        i.paginationList.classList.remove('is-first')
        // if (forMobile()) {
        //   i.paginationList.classList.remove('is-last')
        // } else {
        // }
        i.paginationList.classList.add('is-last')
      },
      // 未使用になったためコメントアウト
      // to4: () => {
      //   i.item[0].remove(klass.current)
      //   i.item[1].add(klass.hidden)
      //   i.item[1].remove(klass.current)
      //   i.item[2].remove(klass.current)
      //   i.item[3].remove(klass.current)
      //   i.item[4].add(klass.current)
      //   i.bg[0].remove(klass.current)
      //   i.bg[1].remove(klass.current)
      //   i.bg[2].add(klass.current)
      //   i.pagination[0].classList.remove(klass.current)
      //   i.pagination[1].classList.remove(klass.current)
      //   i.pagination[2].classList.remove(klass.current)
      //   i.pagination[3].classList.remove(klass.current)
      //   i.pagination[4].classList.add(klass.current)
      //   outline03ListToggle(false)
      //   i.paginationList.classList.remove('is-first')
      //   i.paginationList.classList.add('is-last')
      // },
    }

    const animeEvent = {
      /**
       * ページ変更
       * @param position
       * @param page
       */
      change: (page) => {
        scrollSmooth('#pagetop')
        body.classList.remove('is-next')
        if (page >= 0) {
          pageAnimation[`to${page}`]()
        }
      },
      next: (flag) => {
        setHiddenContent(false)
        body.classList.add('is-next')

        scrollSmooth('#breadcrumbs')
        outlinePosition()
      },
      prev: () => {
        scrollSmooth('#pagetop')
        body.classList.remove('is-next')
        serviceNavLink.forEach((link, length) => {
          link.classList.remove(klass.current)
        })
        outlinePosition()
      },
    }

    i.paginationPrev.addEventListener('click', (e) => {
      e.preventDefault()
      if (i.current === 0) {
        return
      }
      i.current -= 1
      animeEvent.change(i.current)
    })

    i.paginationNext.addEventListener('click', (e) => {
      e.preventDefault()
      // if ((forMobile() && i.current === 4) || (!forMobile() && i.current === 3)) {
      if (i.current === 3) {
        animeEvent.next()
        return
      }
      i.current += 1
      animeEvent.change(i.current)
    })

    /**
     * 横スクロール部イベント指定
     * @param scrollType {string} up / down
     */
    const sliderEvent = (scrollType) => {
      if (scrollType === 'up') {
        if (i.current === 0) {
          return
        }
        i.current -= 1
        animeEvent.change(i.current)
      } else if (scrollType === 'down') {
        // if ((forMobile() && i.current === 4) || (!forMobile() && i.current === 3)) {
        if (i.current === 3) {
          animeEvent.next()
          return
        }
        i.current += 1
        animeEvent.change(i.current)
      }
    }
    const outlineScrollEvent = (e) => {
      e.preventDefault()
      const startTime = new Date().getTime()
      const scrollType = e.wheelDelta > 0 ? 'up' : e.wheelDelta < 0 ? 'down' : null

      // 一定時間内のイベントを通過させない
      if (startTime - i.lastTime < i.WHEEL_INTERVAL) {
        return
      }
      sliderEvent(scrollType)
      i.lastTime = startTime
    }
    const outlineTouchEvent = (e) => {
      e.preventDefault()
      const startTime = new Date().getTime()
      const scrollType = touchMoveData.toTop || touchMoveData.toLeft ? 'up' : 'down'
      const touchFlag =
        touchMoveData.toTop ||
        touchMoveData.toLeft ||
        touchMoveData.toBottom ||
        touchMoveData.toRight

      // 一定時間内のイベントを通過させない
      if (startTime - i.lastTime < i.WHEEL_INTERVAL || !touchFlag) {
        return
      }
      sliderEvent(scrollType)
      i.lastTime = startTime
    }

    i.pagination[0].addEventListener('click', pageAnimation.to0)
    i.pagination[1].addEventListener('click', pageAnimation.to1)
    i.pagination[2].addEventListener('click', pageAnimation.to2)
    i.pagination[3].addEventListener('click', pageAnimation.to3)
    // i.pagination[4].addEventListener('click', pageAnimation.to4)

    let tempWinWidth = 0
    window.addEventListener('load', (e) => {
      i.content.classList.add(klass.view)
      tempWinWidth = window.innerWidth
      touchEvent = window.ontouchstart
      touchPoints = navigator.maxTouchPoints

      i.content.addEventListener('touchstart', getTouch.start)
      i.content.addEventListener('touchmove', getTouch.move)
      i.content.addEventListener('touchend', getTouch.result)
      i.content.addEventListener('wheel', outlineScrollEvent)
      i.content.addEventListener('touchend', outlineTouchEvent)

      if (touchEvent !== undefined && 0 < touchPoints) {
      } else {
        i.content.addEventListener('scroll', outlineScrollEvent)
      }

      // if (hashLink && forMobile()) {
      //   i.current = 4
      //   pageAnimation.to4()
      // } else if (hashLink) {
      //   i.current = 3
      //   pageAnimation.to3()
      // }
      if (hashLink) {
        i.current = 3
        pageAnimation.to3()
      }
    })

    const serviceNav = document.querySelector('.service-nav')
    const outlinePosition = () => {
      const windowHeight = window.innerHeight
      const offsetTop = window.scrollY
      const footerTop = offsetTop + i.footer.getBoundingClientRect().top - 44

      if (offsetTop >= footerTop - windowHeight) {
        serviceNav.classList.add(klass.bottom)
        serviceNav.classList.remove(klass.fixed)
      } else {
        serviceNav.classList.remove(klass.bottom)
        serviceNav.classList.add(klass.fixed)
      }
    }

    window.addEventListener('scroll', outlinePosition)
    window.addEventListener('load', outlinePosition)

    /**
     * service section scroll nav
     */
    const serviceNavLink = Array.from(
      document.querySelectorAll('.service-nav-list .js-service-scroll'),
    )
    const sectionArr = []
    Array.from(serviceNavLink).forEach((link, length) => {
      const target = link.getAttribute('href')
      sectionArr.push(target)

      link.addEventListener('click', (e) => {
        e.preventDefault()
        i.current = 3
        if (length === 0) {
          i.current = 0
          pageAnimation.to1()
          body.classList.remove('is-next')
        } else {
          setHiddenContent(false)
          body.classList.add('is-next')
        }
        // document.querySelector('.service-nav').classList.remove('is-open')
        setTimeout(scrollSmooth(target), 50)
      })
    })

    const sideNavFunc = () => {
      const winTop = window.scrollY
      const winHeight = window.innerHeight
      const top = winTop + winHeight / 2

      sectionArr.forEach((target, length) => {
        const link = serviceNavLink[length]
        const targetDom = document.querySelector(target)
        const secTop = winTop + targetDom.getBoundingClientRect().top
        const secBottom = secTop + targetDom.clientHeight

        if (secTop <= top && secBottom >= top) {
          link.classList.add(klass.current)

          if (length === 0) {
            body.classList.remove('is-next')
          } else {
            body.classList.add('is-next')
          }
        } else {
          link.classList.remove(klass.current)
        }
        if (body.classList.contains('is-next')) {
        }
      })
    }
    window.addEventListener('resize', sideNavFunc)
    window.addEventListener('scroll', sideNavFunc)
    window.addEventListener('load', sideNavFunc)

    document.querySelector('.service-nav__button').addEventListener('click', () => {
      document.querySelector('.service-nav').classList.toggle('is-open')
    })
  }
}

export { serviceFunc }
