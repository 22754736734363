import Swiper from 'swiper/bundle'

const sliderInit = () => {
  /**
   * slider init
   */
  if (document.querySelector('.js-top-works-slider')) {
    const worksSlider = new Swiper('.js-top-works-slider', {
      slidesPerView: 'auto',
      spaceBetween: 16,
      grabCursor: true,
      breakpoints: {
        1440: {
          spaceBetween: 24,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }

  if (document.querySelector('.js-single-works-slider')) {
    const worksSingleSlider = new Swiper('.js-single-works-slider', {
      slidesPerView: 'auto',
      spaceBetween: 16,
      grabCursor: true,
      breakpoints: {
        1440: {
          spaceBetween: 24,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    // paginationが必要ない場合は要素を非表示
    const slideLength = document.querySelectorAll('.js-single-works-slider .swiper-slide').length
    const slideLengthCheck = () => {
      if (slideLength <= 2 && 768 < window.innerWidth) {
        document.querySelector('.js-single-work-slider-pagination').style.display = 'none'
      } else {
        document.querySelector('.js-single-work-slider-pagination').style.display = 'flex'
      }
    }

    window.addEventListener('load', slideLengthCheck)
    window.addEventListener('resize', slideLengthCheck)
  }

  if (document.querySelector('.js-review-slider')) {
    const reviewSlider = new Swiper('.js-review-slider', {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 24,
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        959: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 32,
        },
      },
    })
    //
    const reviewSlidePagination = document.querySelector('.js-slider-pagination ')
    const reviewPaginateChange = (begin, end) => {
      if (begin) {
        reviewSlidePagination.classList.add('is-first-index')
        reviewSlidePagination.classList.remove('is-last-index')
      } else if (end) {
        reviewSlidePagination.classList.remove('is-first-index')
        reviewSlidePagination.classList.add('is-last-index')
      } else {
        reviewSlidePagination.classList.remove('is-first-index')
        reviewSlidePagination.classList.remove('is-last-index')
      }
    }
    reviewSlider.on('slideChange', (swiper) => {
      reviewPaginateChange(swiper.isBeginning, swiper.isEnd)
    })
  }
}

export { sliderInit }
