import Masonry from 'masonry-layout'

const msnry = () => {
  if (document.querySelector('.grid')) {
    const masonryLayout = new Masonry('.grid', {
      itemSelector: '.grid-item',
      columnWidth: '.grid-item',
      gutter: 24,
      percentPosition: true,
      horizontalOrder: true,
    })
    window.addEventListener('resize', masonryLayout.reloadItems)
  }
}
export { msnry }
