import { klass, scrollSmooth, forMobile } from './common'

const MINIMUM_DISTANCE = 15
const touchMoveData = {
  startX: 0,
  startY: 0,
  endX: 0,
  endY: 0,
  distanceX: 0,
  distanceY: 0,
  toTop: false,
  toRight: false,
  toBottom: false,
  toLeft: false,
}
const getTouch = {
  start: (e) => {
    e.preventDefault()
    touchMoveData.startX = e.touches[0].pageX
    touchMoveData.startY = e.touches[0].pageY
  },
  move: (e) => {
    e.preventDefault()
    touchMoveData.endX = e.changedTouches[0].pageX
    touchMoveData.endY = e.changedTouches[0].pageY
  },
  result: (e) => {
    e.preventDefault()
    touchMoveData.distanceX = Math.abs(touchMoveData.endX - touchMoveData.startX)
    touchMoveData.distanceY = Math.abs(touchMoveData.endY - touchMoveData.startY)

    // 右向きスワイプ 左へ
    touchMoveData.toRight =
      touchMoveData.endX < touchMoveData.startX &&
      touchMoveData.distanceX > MINIMUM_DISTANCE &&
      touchMoveData.distanceX > touchMoveData.distanceY
    // 左向きスワイプ 右へ
    touchMoveData.toLeft =
      touchMoveData.endX > touchMoveData.startX &&
      touchMoveData.distanceX > MINIMUM_DISTANCE &&
      touchMoveData.distanceX > touchMoveData.distanceY
    // 下向きスワイプ 上へ
    touchMoveData.toTop =
      touchMoveData.endY > touchMoveData.startY &&
      touchMoveData.distanceY > MINIMUM_DISTANCE &&
      touchMoveData.distanceX < touchMoveData.distanceY
    // 上向きスワイプ 下へ
    touchMoveData.toBottom =
      touchMoveData.endY < touchMoveData.startY &&
      touchMoveData.distanceY > MINIMUM_DISTANCE &&
      touchMoveData.distanceX < touchMoveData.distanceY
  },
}

let hashLink = false

const businessFunc = () => {
  if (document.querySelector('.business-inner')) {
    const body = document.querySelector('body')
    const i = {
      current: 1,
      WHEEL_INTERVAL: 1500,
      lastTime: 0,
      header: document.querySelector('.header'),
      headerHeight: document.querySelector('.header').getBoundingClientRect().height,
      wrapper: document.querySelector('#business-outline'),
      content: document.querySelector('.business-outline__content'),
      after: document.querySelector('.page-business__after-content'),
      footer: document.querySelector('.footer'),
      flag: {
        view: false,
        throw: false,
        throw2: false,
      },
      title: document.querySelector('.business-outline__item-heading__title').classList,
      item: [
        document.querySelector('.business-outline__item-body01').classList,
        document.querySelector('.business-outline__item-body02').classList,
      ],
      paginationList: document.querySelector('.business-outline__pagination'),
      paginationPrev: document.querySelector('.slide-pagination__prev'),
      paginationNext: document.querySelector('.slide-pagination__next'),
      pagination: [
        document.querySelector('.business-outline__pagination-item01'),
        document.querySelector('.business-outline__pagination-item02'),
      ],
    }
    let touchEvent = window.ontouchstart
    let touchPoints = navigator.maxTouchPoints
    let corporate01Text = []
    let corporate02Text = []

    /**
     * 非表示コンテンツ調整
     * @param hidden {boolean}
     */
    const setHiddenContent = (hidden) => {
      if (hidden) {
        i.after.style.height = '0px'
        i.footer.style.height = '0px'
        i.footer.style.margin = '0'
      } else {
        i.after.style.height = ''
        i.footer.style.height = ''
        i.footer.style.margin = ''
      }
    }

    if (!location.hash) {
      setHiddenContent(true)
    }

    const pageAnimation = {
      to0: () => {
        //
      },
      to1: () => {
        i.item[0].remove(klass.hidden)
        i.item[1].add(klass.hidden)
        setTimeout(() => {
          i.item[1].remove(klass.current)
          setTimeout(() => {
            i.item[0].add(klass.current)
            i.item[1].remove(klass.hidden)
          }, 10)
        }, 500)
        i.pagination[0].classList.add(klass.current)
        i.pagination[1].classList.remove(klass.current)
        i.paginationList.classList.add('is-first')
        i.paginationList.classList.remove('is-last')
      },
      to2: () => {
        i.item[0].add(klass.hidden)
        i.item[1].remove(klass.hidden)
        setTimeout(() => {
          i.item[0].remove(klass.current)
          setTimeout(() => {
            i.item[0].remove(klass.hidden)
            i.item[1].add(klass.current)
          }, 10)
        }, 500)
        i.pagination[0].classList.remove(klass.current)
        i.pagination[1].classList.add(klass.current)
        i.paginationList.classList.remove('is-first')
        i.paginationList.classList.add('is-last')
      },
    }

    const animeEvent = {
      /**
       * ページ変更
       * @param position
       * @param page
       */
      change: (page) => {
        if (page !== 0) {
          pageAnimation[`to${page}`]()
          scrollSmooth('#pagetop')
        }
      },
      next: (flag) => {
        setHiddenContent(false)
        body.classList.add('is-next')

        if (!flag) {
          scrollSmooth('#breadcrumbs')
        }
      },
      prev: () => {
        scrollSmooth('#pagetop')
        body.classList.remove('is-next')
      },
    }

    i.paginationPrev.addEventListener('click', (e) => {
      e.preventDefault()
      if (i.current === 1) {
        return
      }
      i.current -= 1
      animeEvent.change(i.current)
    })

    i.paginationNext.addEventListener('click', (e) => {
      e.preventDefault()
      if (i.current === 3) {
        animeEvent.next()
        return
      }
      i.current += 1
      animeEvent.change(i.current)
    })

    /**
     * 横スクロール部イベント指定
     * @param scrollType {string} up / down
     */
    const sliderEvent = (scrollType) => {
      if (scrollType === 'up') {
        if (i.current === 1) {
          return
        }
        i.current -= 1
        animeEvent.change(i.current)
      } else if (scrollType === 'down') {
        if (i.current === 2) {
          animeEvent.next()
          return
        }
        i.current += 1
        animeEvent.change(i.current)
      }
    }
    const visionScrollEvent = (e) => {
      e.preventDefault()
      const startTime = new Date().getTime()
      const scrollType = e.wheelDelta > 0 ? 'up' : e.wheelDelta < 0 ? 'down' : null

      // 一定時間内のイベントを通過させない
      if (startTime - i.lastTime < i.WHEEL_INTERVAL) {
        return
      }
      sliderEvent(scrollType)
      i.lastTime = startTime
    }
    const visionTouchEvent = (e) => {
      e.preventDefault()
      const startTime = new Date().getTime()
      const scrollType = touchMoveData.toTop || touchMoveData.toLeft ? 'up' : 'down'
      const touchFlag =
        touchMoveData.toTop ||
        touchMoveData.toLeft ||
        touchMoveData.toBottom ||
        touchMoveData.toRight

      // 一定時間内のイベントを通過させない
      if (startTime - i.lastTime < i.WHEEL_INTERVAL || !touchFlag) {
        return
      }
      sliderEvent(scrollType)
      i.lastTime = startTime
    }

    let tempWinWidth = 0
    window.addEventListener('load', () => {
      corporate01Text = Array.from(
        document.querySelectorAll('.business-outline__item-body01 .kr-anime-row'),
      )
      corporate02Text = Array.from(
        document.querySelectorAll('.business-outline__item-body02 .kr-anime-row'),
      )
      i.wrapper.classList.add(klass.current)
      tempWinWidth = window.innerWidth

      touchEvent = window.ontouchstart
      touchPoints = navigator.maxTouchPoints
      i.content.classList.add(klass.view)

      if (location.hash) {
        i.current = 2
        animeEvent.change(i.current)
        animeEvent.next(true)
        setTimeout(scrollSmooth(location.hash), 50)
      } else {
        pageAnimation.to1()
        setHiddenContent(true)
      }

      i.content.addEventListener('touchstart', getTouch.start)
      i.content.addEventListener('touchmove', getTouch.move)
      i.content.addEventListener('touchend', getTouch.result)
      i.content.addEventListener('wheel', visionScrollEvent)
      i.content.addEventListener('touchend', visionTouchEvent)

      if (touchEvent !== undefined && 0 < touchPoints) {
      } else {
        i.content.addEventListener('scroll', visionScrollEvent)
      }
    })
    i.pagination[0].addEventListener('click', pageAnimation.to1)
    i.pagination[1].addEventListener('click', pageAnimation.to2)

    Array.from(document.querySelectorAll('.js-business-page-nav')).forEach((link, length) => {
      const target = link.getAttribute('href')

      link.addEventListener('click', (e) => {
        e.preventDefault()
        if (target === '#pagetop') {
          pageAnimation.to1()
          body.classList.remove('is-next')
        } else {
          setHiddenContent(false)
          body.classList.add('is-next')
        }
        body.classList.remove(klass.nav)
        setTimeout(scrollSmooth(target), 50)
      })
    })
  }
}
export { businessFunc }
