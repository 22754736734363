import inView from 'in-view'
import smoothscroll from 'smoothscroll-polyfill'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  large: 'is-large',
  nav: 'view-nav',
  bottom: 'is-bottom',
  dark: 'is-dark',
}

/**
 * header background color change
 */
let headerBgChangePosition

const headerBgChange = () => {
  if (document.querySelector('.page-index')) {
    headerBgChangePosition =
      document.querySelector('.js-fv-section').clientHeight -
      document.querySelector('.header').clientHeight -
      20
    return headerBgChangePosition
  } else {
    headerBgChangePosition = document.querySelector('.header').clientHeight
    return headerBgChangePosition
  }
}

const headerBgScrollChange = () => {
  const body = document.querySelector('body')
  if (window.scrollY > headerBgChangePosition) {
    body.classList.add('is-header-background')
  } else {
    body.classList.remove('is-header-background')
  }
}

/**
 * Get the URL parameter value
 *
 * @param name {string} パラメータのキー文字列
 * @param url {string} 対象のURL文字列（任意）パラメータのキー文字列
 * @return string
 */
const getParam = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * scroll inView animation
 */
const inViewSet = () => {
  inView('.inview').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeUp').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeLeft').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeIn').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-inview-top-heading').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-inview-works-detail').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-inview-top-business-detail').on('enter', (el) => {
    el.classList.add('is-view')
  })
}

/**
 * モバイルサイズ判定
 * @return {boolean}
 */
const forMobile = () => window.innerWidth < 768

const scrollSmooth = (targetId, transion) => {
  smoothscroll.polyfill()
  const behavior = transion || 'smooth'
  const rectTop = document.querySelector(targetId).getBoundingClientRect().top
  const top = rectTop + window.scrollY - document.querySelector('.header').clientHeight
  window.scrollTo({ top, behavior })
}

export { klass, forMobile, inViewSet, getParam, headerBgChange, headerBgScrollChange, scrollSmooth }
