import { getParam } from './common'

const worksFunc = () => {
  const filters = Array.from(document.querySelectorAll('.archive-tag-filter'))

  if (filters.length > 0) {
    filters.forEach((filter) => {
      filter.addEventListener('click', (event) => {
        event.defaultPrevented
        filter.classList.toggle('is-current')
        const targetArray = []
        filters.forEach((btn) => {
          if (btn.classList.contains('is-current')) {
            targetArray.push(btn.getAttribute('data-filter'))
          }
        })
        categoryFilter(targetArray)
      })
    })

    /**
     * パラメーター付き遷移の場合読み込み時にフィルタリング
     */
    window.addEventListener('load', () => {
      const parm = getParam('filter') ? `${getParam('filter')}` : null
      if (parm) {
        // パラメーター指定以外を非表示化
        const targetArray = []
        filters.forEach((btn) => {
          if (parm !== btn.getAttribute('data-filter')) {
            btn.classList.remove('is-current')
          }

          if (btn.classList.contains('is-current')) {
            targetArray.push(btn.getAttribute('data-filter'))
          }
        })
        categoryFilter(targetArray)
      }
    })
  }

  /**
   * カテゴリーフィルター実行
   * @param target {Array} フィルタリングクラス名指定
   * @param hidden {boolean} trueの場合に非表示化
   */
  const categoryFilter = (targets) => {
    Array.from(document.querySelectorAll('.filter-target')).forEach((article) => {
      const targetClassList = article.classList
      let view = false

      targetClassList.forEach((k) => {
        const current = targets.includes(k)
        if (current) {
          view = current
        }
      })

      if (view) {
        article.classList.remove('is-hidden')
      } else {
        article.classList.add('is-hidden')
      }
    })
  }
}
export { worksFunc }
