import lottie from 'lottie-web'
import { scrollSmooth } from './common'

/**
 * 24時間以内のアクセス履歴を判定
 */
const fvStartAnimation = () => {
  if (document.querySelector('.js-fv-trigger')) {
    const DAY_TIME = 24 * 60 * 60 * 1000
    const NowDate = new Date().getTime()
    const lastDate = window.localStorage.getItem('lastAccessDate') || 0
    const diffTime = NowDate - lastDate
    if (diffTime > DAY_TIME) {
      // アクセスが24時間以上または初回アクセス時の場合
      fvOnAnimation()
      window.localStorage.setItem('lastAccessDate', NowDate)
    } else {
      // アクセスが24時間以下の場合
      fvOffAnimation()
    }
    const nextBtn = document.querySelector('.js-fv-btn')
    nextBtn.addEventListener('click', (e) => {
      e.preventDefault()
      scrollSmooth(nextBtn.getAttribute('href'))
      return false
    })
  }
}

/**
 * FV animation
 */

const lottieFvLogoInit = {
  container: document.querySelector('#loadingLogo'),
  renderer: 'svg',
  loop: false,
  autoplay: false,
  path: '/wp-content/themes/asnova_corp_v1/assets/lottie/loading_Asnova_0204.json',
}
const lottieFvCatchCopyInit = {
  container: document.querySelector('#catchCopy'),
  renderer: 'svg',
  loop: false,
  autoplay: false,
  path: '/wp-content/themes/asnova_corp_v1/assets/lottie/FV.json',
}

// FV animation on
const fvOnAnimation = () => {
  const body = document.querySelector('body')
  const lottieFvLogo = lottie.loadAnimation(lottieFvLogoInit)
  const lottieFvCatchCopy = lottie.loadAnimation(lottieFvCatchCopyInit)

  let timer = 0
  document.querySelector('body').style.overflow = 'hidden'

  lottieFvLogo.play()

  setTimeout(() => {
    body.classList.add('is-loaded')
  }, (timer += 2000))

  setTimeout(() => {
    lottieFvCatchCopy.play()
    body.classList.add('is-header-visible')
  }, (timer += 1000))

  setTimeout(() => {
    body.classList.add('is-fv-anim-end')
    body.style.overflow = 'auto'
  }, (timer += 3000))

  setTimeout(() => {
    document.querySelector('.js-fv-btn').style.opacity = '1'
  }, (timer += 1000))
}

// FV animation off
const fvOffAnimation = () => {
  const body = document.querySelector('body')
  const lottieFvLogo = lottie.loadAnimation(lottieFvLogoInit)
  const catchCopyDom = document.querySelector('.fv__catch-copy--is-loaded')
  lottieFvLogo.play()
  catchCopyDom.style.display = 'block'
  body.style.overflow = 'hidden'
  body.classList.add('is-fv-anim-end')
  body.style.overflow = 'auto'
  body.classList.add('is-header-visible')
  document.querySelector('.js-fv-btn').style.opacity = '1'

  setTimeout(() => {
    body.classList.add('is-loaded')
  }, 1000)
}

export { fvStartAnimation }
