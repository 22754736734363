import { ua } from './modules/ua'
import {
  klass,
  scrollSmooth,
  inViewSet,
  headerBgChange,
  headerBgScrollChange,
} from './modules/common'
import { sliderInit } from './modules/slider'
import { fvStartAnimation } from './modules/toppage'
import { msnry } from './modules/msnry'
import { accordion } from './modules/accordion'
import { serviceFunc } from './modules/service'
import { worksFunc } from './modules/works'
import { corporateFunc } from './modules/corporate'
import { businessFunc } from './modules/business'

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.init()
  }

  init() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)

    window.addEventListener('resize', () => {
      headerBgChange()
    })

    window.addEventListener('scroll', () => {
      headerBgScrollChange()
    })
    this.initPages()

    sliderInit()
    accordion()
    msnry()
    serviceFunc()
    worksFunc()
    corporateFunc()
    businessFunc()
  }

  initPages() {
    inViewSet()

    // ナビゲーション開閉
    document.querySelector('.js-nav-trigger').addEventListener('click', () => {
      document.querySelector('body').classList.toggle(klass.nav)
    })

    if (!this.body.classList.contains('page-index')) {
      setTimeout(() => {
        this.body.classList.add('is-loaded2')
      }, 1000)
    }

    // スムーススクロール
    const smoothScrollTarget = Array.from(document.querySelectorAll('.js-smooth-scroll'))
    if (smoothScrollTarget.length > 0) {
      smoothScrollTarget.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault()
          const targetId = e.target.hash
          document.querySelector('body').classList.remove('view-nav')

          if (!document.querySelector(targetId)) {
            return false
          }
          scrollSmooth(targetId)
        })
      })
    }
  }
}

const windowLoadFunc = () => {
  fvStartAnimation()
  headerBgChange()
}

document.addEventListener('DOMContentLoaded', () => {
  window.asnova = new App()
})

window.addEventListener('load', windowLoadFunc)
